import {slideDown, slideUp} from "./utilities/slide";
import {XhrService} from "../ts/xhr.service";
import {addErrorClass, addLoadingClass, removeLoadingClass} from "./utilities/updateClasses";

function toggleVariants(itemlistElement, itemlistVariants) {
    if (itemlistElement.classList.contains("is-active")) {
        for (let variant of itemlistVariants) {
            slideUp(variant);
        }
        itemlistElement.classList.remove("is-active");
    } else {
        for (let variant of itemlistVariants) {
            slideDown(variant, 200, "linear", () => {
            }, "grid");
        }
        itemlistElement.classList.add("is-active");
    }
}

export function initItemlistToggle(query = document.querySelectorAll('.itemlistElement .js-toggleItemlistVariants')) {
    for (let toggle of query) {
        let itemlistElement = toggle.closest('.itemlistElement');
        toggle.addEventListener("click", function () {
            let toggles = itemlistElement.querySelectorAll(".js-toggleItemlistVariants");
            let itemlistVariants = itemlistElement.querySelectorAll('.itemlistElement__wrapper--isVariant');
            if (itemlistVariants.length === 0) {
                let itemNo = itemlistElement.dataset.itemno;
                let inputCounter = itemlistElement.dataset.inputcounter;
                let formData = new FormData();
                formData.set('item_no', itemNo);
                formData.set('input_counter', inputCounter);
                for(let e of toggles){
                    addLoadingClass(e);
                }
                XhrService.generalAjaxCall('loadItemVariants', formData, 'POST', 'json').then(response => {
                    if (!response.status) {
                        throw "Could not get data";
                    }
                    let renderedVariants = response.renderedVariants;
                    let newElement = document.createElement('div');
                    newElement.innerHTML = renderedVariants;
                    while (newElement.firstChild) {
                        itemlistElement.appendChild(newElement.firstChild);
                    }
                    newElement.remove();
                    itemlistVariants = itemlistElement.querySelectorAll('.itemlistElement__wrapper--isVariant');
                    if (itemlistVariants.length > 0) {
                        toggleVariants(itemlistElement, itemlistVariants);
                    }
                    for(let e of toggles){
                        removeLoadingClass(e);
                    }
                })
                .catch((e) => {
                    console.error('Error:', e);
                    console.error(formData);
                    for(let e of toggles){
                        addErrorClass(e);
                    }
                });
            } else {
                toggleVariants(itemlistElement, itemlistVariants);
            }
        });
    }
}